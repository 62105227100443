const productExtractor = (
  slug: string,
  data: {
    _id: string;
    productLandingPage?: {
      slug: {
        current: string;
      };
    };
  },
  language: string,
  siteUrl: string,
  siteName: string,
  reviews?: {
    averageRating?: string;
    reviewCount?: string;
  }
) => {
  const productSlug = data.productLandingPage ? data.productLandingPage.slug.current : "products";
  const url = `${siteUrl}/${productSlug}/${slug}/`;

  if(reviews?.averageRating?.length === undefined && reviews?.reviewCount?.length === undefined) {
    return {
      brand: siteName,
      url: url,
      gtin8: data._id
    }
  }

  else {
    return {
      brand: siteName,
      url: url,
      gtin8: data._id,
      aggregateRating: {
        "@type": "aggregateRating",
        ratingValue: reviews?.averageRating,
        reviewCount: reviews?.reviewCount
      }
    }
  }


}

export default productExtractor;